.site-header {
  z-index: 100;
  position: sticky;
  top: 0;
}

.site-header-top {
  @include transition-fast;
  background: white;
  color: black;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: map-get($header-height, small);

  @include breakpoint(large) {
    height: map-get($header-height, large);
  }

  .js-menu-opened & {
    background: black;
  }
}

.site-header-logo {
  font-weight: 500;
  margin: -2px 0 0;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint(md) {
    font-size: 32px;
  }

  @include breakpoint(large) {
    margin: 0;
    font-size: 38px;
  }

  a {
    display: block;
    line-height: inherit;
    color: currentColor;
    margin: auto;
    text-decoration: none;
  }

  .js-menu-opened & {
    a {
      @include transition-fast;
      color: white;
    }
  }
}

.site-menu-btn {
  @include transition-med;
  width: 28px;
  height: 19px;
  position: relative;
  transform: rotate(0deg);
  cursor: pointer;
  color: currentColor;
  background: transparent;
  border: none;
  outline: none;

  span {
    @include transition-fast;
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: currentColor;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }

  .js-menu-opened & {
    span {
      background: white;
    }

    span:nth-child(1) {
      top: 12px;
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 12px;
      width: 0%;
      left: 50%;
    }
  }
}

.js-menu-opened {
  overflow: hidden;
}

.site-header-nav {
  @include transition-fast;
  @include fluid-font(22px, 34px);
  background: black;
  color: white;
  position: fixed;
  top: map-get($header-height, small);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: calc(var(--vertical-offset) / 2) 0 80px;
  opacity: 0;
  pointer-events: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include breakpoint(large) {
    top: map-get($header-height, large);
  }

  .js-menu-opened & {
    opacity: 1;
    pointer-events: all;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.site-header-nav-top {
  a {
    text-transform: uppercase;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    line-height: 0.75;
    font-size: 13vw;
    color: white;
    text-decoration: none;

    @media (min-width: 768px) {
      font-size: 7vw;
    }

    &:hover {
      color: #8c8c8c;
    }
  }

  .last {
    padding-top: 3em;
  }
}

.site-header-nav-bottom {
  display: grid;
  gap: 1.5rem;
  max-width: 64rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  line-height: 1;
  text-transform: uppercase;
  padding-block: 3em;
  grid-template-columns: 50% 1fr;

  @media (min-width: 768px) {
    font-size: inherit;
    grid-template-columns: 45% 35% 1fr;
  }

  ul > :not([hidden]) ~ :not([hidden]) {
    margin-top: 0.5rem;
  }

  a {
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    color: white;
    text-decoration: none;

    &:hover {
      color: #8c8c8c;
    }
  }

  .social-mobile {
    padding-top: 32px;

    @media (min-width: 768px) {
      display: none;
    }

    a {
      display: block;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 300ms;
      width: 24px;
      height: 24px;

      &:hover {
        color: #8c8c8c;
      }
    }
  }

  .social-desktop {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    a {
      display: block;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 300ms;
      width: 28px;
      height: 28px;

      &:hover {
        color: #8c8c8c;
      }
    }
  }

  .flavor-product-link-4499526778979:hover {
    color: #db0032;
  }

  .flavor-product-link-4504995004515:hover {
    color: #f4436c;
  }

  .flavor-product-link-4505000476771:hover {
    color: #ff8672;
  }

  .flavor-product-link-4499571408995:hover {
    color: #ff8300;
  }

  .flavor-product-link-4505000935523:hover {
    color: #ffc600;
  }

  .flavor-product-link-4504997986403:hover {
    color: #ffd720;
  }

  .flavor-product-link-4504997789795:hover {
    color: #93d500;
  }

  .flavor-product-link-4499570688099:hover {
    color: #4a9462;
  }

  .flavor-product-link-4504999133283:hover {
    color: #006098;
  }

  .flavor-product-link-4505000181859:hover {
    color: #dcc7b7;
  }

  .flavor-product-link-4504998445155:hover {
    color: #ddb5c8;
  }

  .flavor-product-link-4504998772835:hover {
    color: #752e4a;
  }
}

.section-pad {
  padding-top: var(--vertical-offset);
  padding-bottom: var(--vertical-offset);
}

.site-footer {
  background: $black;
  color: $white;

  ul {
    margin: 0;
    padding: 0;
    line-height: 1;
    list-style: none;

    & > :not([hidden]) ~ :not([hidden]) {
      margin-top: 0.25rem;
    }
  }
}

.site-footer-nav {
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  @media (min-width: 1024px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  @media (min-width: 1280px) {
    @include fluid-font(22px, 34px);
  }

  a {
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    color: white;
    text-decoration: none;
    font-size: 2.5em;

    &:hover {
      color: #8c8c8c;
    }

    @media (min-width: 768px) {
      font-size: inherit;
    }
  }
}

.site-footer-nav-group {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2.5rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.site-footer-nav-group-first {
  @media (min-width: 768px) {
    grid-column: span 1 / span 1;
  }
}

.site-footer-blurb {
  line-height: 1;

  & > div {
    margin-left: -48px;

    @media (min-width: 768px) {
      margin: 0;
    }

    strong {
      display: block;
      font-weight: normal;
    }

    a {
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 300ms;
      text-decoration: none;

      &:hover {
        color: #8c8c8c;
      }
    }
  }
}

.site-footer-bottom {
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 5vw;

  @media (min-width: 768px) {
    display: flex;
    margin-top: 10vw;
  }
}

.site-footer-bottom-first {
  @media (min-width: 768px) {
    order: 9999;
  }

  a {
    display: block;
    width: 24px;
    height: 24px;
    color: white;

    &:hover {
      color: #8c8c8c;
    }
  }
}

.site-footer-bottom-last {
  margin-top: 20px;

  @media (min-width: 768px) {
    display: flex;
    margin-top: 0;
    font-size: 16px;
    align-items: center;
  }

  .copyright {
    margin-bottom: 16px;

    @media (min-width: 768px) {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }

  .legal {
    a {
      color: white;
      text-decoration: none;

      &:hover {
        color: #8c8c8c;
      }

      &:not(:first-child) {
        margin-left: 10px;

        @media (min-width: 768px) {
          margin-left: 24px;
        }
      }
    }
  }
}

.grid-margin-x {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.grid-x {
  display: flex;
  flex-flow: row wrap;
}

.grid-margin-x > .cell {
  width: calc(100% - 2.5rem);
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.grid-margin-x > .small-7 {
  width: calc(58.33333% - 2.5rem);
}

.grid-margin-x > .small-5 {
  width: calc(41.66667% - 2.5rem);
}

.grid-margin-x > .tablet-12 {
  @include breakpoint(tablet) {
    width: calc(100% - 2.5rem);
  }
}

.grid-margin-x > .tablet-8 {
  @include breakpoint(tablet) {
    width: calc(66.66667% - 2.5rem);
  }
}

.grid-margin-x > .tablet-6 {
  @include breakpoint(tablet) {
    width: calc(50% - 2.5rem);
  }
}

.grid-margin-x > .tablet-4 {
  @include breakpoint(tablet) {
    width: calc(33.33333% - 2.5rem);
  }
}

.cell {
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: 100%;
}
