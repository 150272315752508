@font-face {
  font-family: "founders-grotesk";
  src: url("../fonts/founders-grotesk-web-regular.woff2") format("woff2"),
       url("../fonts/founders-grotesk-web-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "founders-grotesk";
  src: url("../fonts/founders-grotesk-web-medium.woff2") format("woff2"),
       url("../fonts/founders-grotesk-web-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$breakpoints: (
  'md': 768px,
  'tablet': 768px,
  'lg': 1024px,
  'large': 1024px,
  'xl': 1280px,
  'xxl': 1440px
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else if $breakpoint == md-only {
    @media (min-width: map-get($breakpoints, md)) and (max-width: map-get($breakpoints, lg) - 1px) {
      @content;
    }
  }
  @else if $breakpoint == sm-only {
    @media (max-width: map-get($breakpoints, md) - 1px) {
      @content;
    }
  }
  @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

$fluid-min-width: 320px;
$fluid-max-width: 1440px;

@mixin fluid-type($min-font-size, $max-font-size, $min-vw: $fluid-min-width, $max-vw: $fluid-max-width) {
  @include fluid-font($min-font-size, $max-font-size, $min-vw: $fluid-min-width, $max-vw: $fluid-max-width);
  line-height: 0.75em;
}

@mixin fluid-font($min-font-size, $max-font-size, $min-vw: $fluid-min-width, $max-vw: $fluid-max-width) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin transition-fast($attr: all) {
  transition: $attr 0.2s ease-in-out;
}

@mixin transition-med($attr: all) {
  transition: $attr 0.4s ease-in-out;
}

@mixin list-reset {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

$header-height: (
  small: 65px,
  large: 70px
);

$black: #000;
$white: #fff;
$gray: #d8d8d8;
$gray-dark: #bbb;

:root {
  --vertical-offset: 40px;
}

@media screen and (min-width: 768px) {
  :root {
    --vertical-offset: 5vw;
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

@import 'shopify';
@import 'dropdown';

body {
  font-family: founders-grotesk, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
}


.page-section {
  margin: 20vw 0;

  @include breakpoint(lg) {
    margin: 180px 0;
  }
}

.section-content {
  @include breakpoint(md) {
    margin-bottom: 50px;
  }

  @include breakpoint(lg) {
    margin-bottom: 80px;
  }
}

.section-vote {
  margin-top: 5vw;

  @include breakpoint(md) {
    margin-top: 50px;
  }

  @include breakpoint(lg) {
    margin-top: 80px;
  }
}

.grid-container {
  padding-left: 6vw;
  padding-right: 6vw;
  max-width: 1360px;
  margin: auto;

  @include breakpoint(xl) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.section-gallery {
  outline: none;
  cursor: grab;
  overflow: hidden;
  margin: 10vw 0;

  @include breakpoint(lg) {
    margin: 100px 0;
  }

  .slide {
    margin: 0 2.5vw;

    img {
      display: block;
      max-width: 220px;

      @include breakpoint(md) {
        max-width: 300px;
      }

      @include breakpoint(xl) {
        max-width: 460px;
      }
    }
  }
}

.section-content {
  @include fluid-font(24px, 48px);

  strong {
    text-transform: uppercase;
  }

  .heading {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;

    & + p {
      margin-top: 0;
    }
  }

  p {
    max-width: 940px;
  }

  sup {
    font-size: 14px;

    @include breakpoint(lg) {
      top: -1.5em;
    }
  }

  em {
    font-style: normal;
  }

  .em-1 {
    color: #ffc600;
  }

  .em-2 {
    color: #96d600;
  }

  .em-3 {
    color: #ff8773;
  }

  .sources {
    @include breakpoint(md) {
      display: flex;
    }
  }

  .source {
    font-size: 14px;
    color: #adadad;

    &:last-child {
      @include breakpoint(md) {
        margin-left: 20px;
      }
    }

    a {
      color: #adadad;
    }
  }

  .closing {
    overflow: hidden;

    span {
      width: 24px;
      margin-right: 8px;
      float: left;
      position: relative;
      top: 3px;

      @include breakpoint(md) {
        width: 32px;
        top: auto;
      }
    }
  }
}

.section-vote {
  h2 {
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
  }
}

.generator-container {
  margin-top: 32px;

  @include breakpoint(md) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0;
  }
}

.generator-actions {
  @include fluid-font(20px, 48px);
  margin-top: 36px;
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 64px;
  }

  .btn-primary,
  .btn-secondary {
    background: transparent;
    border: 2px solid black;
    display: block;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    padding: 15px;
    text-align: center;
    width: 100%;

    @include breakpoint(md) {
      width: calc(50% - 16px);
    }

    &:disabled {
      opacity: 0.2;
    }

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  .btn-primary {
    background: black;
    color: white;

    &:not(:disabled):hover {
      opacity: 0.7;
    }
  }

  .btn-secondary {
    color: black;
    margin-top: 16px;

    @include breakpoint(md) {
      margin: 0;
    }

    &:not(:disabled):hover {
      background: black;
      color: white;
    }
  }

  .calendar-action {
    width: 100%;
    margin-top: 10px;

    &.disabled {
      opacity: 0.2;
    }

    &:not(.disabled) {
      cursor: pointer;
    }

    &:not(.disabled):hover {
      .edd-value {
        background: black;
        color: white;
      }
    }

    @include breakpoint(md) {
      margin-top: 0;
      width: calc(50% - 16px);
    }

    .edd-root,
    .edd-head {
      height: 100%;
    }

    .edd-value {
      border: 2px solid black;
      text-transform: uppercase;
      text-align: center;
      min-height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.form-wrapper {
  @include breakpoint(md) {
    width: calc(50% + 32px);
  }

  @include breakpoint(lg) {
    width: calc(50% - 32px);
  }

  @include breakpoint(xl) {
    width: 50%;
  }

  ::-webkit-input-placeholder {
    color: #d8d8d8;
  }
  ::-moz-placeholder {
    color: #d8d8d8;
  }
  :-ms-input-placeholder {
    color: #d8d8d8;
  }

  .edd-value {
    color: #d8d8d8;
  }
}

.form-field {
  display: block;
  align-items: baseline;
  padding: 15px 0;

  &:not(.filled) input {
    color: #d8d8d8;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  input,
  .edd-value {
    @include fluid-font(32px, 70px);
    appearance: none;
    border: none;
    text-transform: uppercase;
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    outline: none;
  }
}

.MuiInputBase-root {
  font: inherit !important;
  line-height: inherit !important;
}

.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint,
.MuiPickersDay-daySelected {
  background: black !important;
}

.MuiPickersClockPointer-thumb {
  border-color: black !important;
}

.MuiButton-root {
  color: black !important;
  font-family: inherit !important;
}

.MuiTypography-root {
  font-family: inherit !important;
}

.MuiPickersToolbar-toolbar {
  background: white !important;

  .MuiPickersToolbarText-toolbarTxt {
    color: black !important;
  }

  h6.MuiPickersToolbarText-toolbarBtnSelected {
    color: white !important;
    background: black !important;
    padding: 0 10px;
  }
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.rc-time-picker-panel {
  width: auto !important;
}

.rc-time-picker-panel-input-wrap {
  display: none !important;
}

.rc-time-picker-panel-inner {
  border-radius: 0 !important;
  border-color: black !important;
  box-shadow: none !important;
}

.rc-time-picker-panel-select {
  text-transform: uppercase !important;
  font-size: 48px !important;
  max-height: 250px !important;
  width: auto !important;
  border: none !important;
}

.rc-time-picker-panel-select li {
  height: auto !important;
  line-height: initial !important;
  padding: 3px 8px !important;
  text-align: center !important;
  outline: none !important;
}

li.rc-time-picker-panel-select-option-selected {
  font-weight: normal !important;
  background: black !important;
  color: white !important;
}

.rc-time-picker-panel-select li:hover:not(.rc-time-picker-panel-select-option-selected) {
  background: white !important;
}

.form-field--name.filled input {
  color: #ffd720;
}

.form-field--method.filled .edd-value {
  color: #deb5c7;
}

.form-field--date.filled input {
  color: #006097;
}

.form-field--time.filled .edd-value {
  color: #96d600;
}

.form-field--county.filled input {
  color: #ff8773;
}

.form-checkbox {
  position: relative;
  margin-top: 24px;

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:checked ~ label:before {
      background: black;
    }
  }

  label {
    display: inline-flex;
    align-items: baseline;
    cursor: pointer;

    &:before {
      content: '';
      width: 20px;
      height: 20px;
      border: 2px solid black;
      margin-right: 16px;
      flex-shrink: 0;
      position: relative;
      top: 2px;
    }
  }

  .form-checkbox-label--primary {
    font-size: 24px;
    line-height: 1;
  }

  .form-checkbox-label--secondary {
    margin-top: 6px;
    font-size: 14px;
  }
}

.result-wrapper {
  position: relative;
  padding-top: 125%;
  margin-bottom: 32px;

  @include breakpoint(md) {
    margin: 30px 0 0;
    padding-top: 62.5%;
    width: calc(50% - 64px);
    max-width: 460px;
  }

  @include breakpoint(lg) {
    width: 50%;
    padding-top: 575px;
  }
}

.preview-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  canvas {
    width: 100%;
  }
}

.preview-header,
.preview-footer {
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
}

.preview-fields {
  @include fluid-type(16px, 70px);
  text-transform: uppercase;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  overflow: hidden;
  padding: 20px;

  ::-webkit-input-placeholder {
    color: #d8d8d8;
  }
  ::-moz-placeholder {
    color: #d8d8d8;
  }
  :-ms-input-placeholder {
    color: #d8d8d8;
  }

  select:not(.filled) {
    color: #d8d8d8;
  }
}

.preview-field {
  display: block;

  input,
  select {
    appearance: none;
    border: none;
    text-transform: uppercase;
    padding: 0;
    outline: none;
  }

  .filled {
    font-weight: 500;
  }
}

.section-ticker {
  overflow: hidden;
}

.ticker {
  &--first {
    padding-left: 5vw;
  }

  &--second {
    padding-left: 2vw;
  }

  &--third {
    padding-left: 4vw;
  }

  &--fourth {
    padding-left: 8vw;
  }
}

.ticker-item {
  @include fluid-type(36px, 48px);
  text-transform: uppercase;
  padding-right: 36px;
}

.section-cta {
  h2 {
    @include fluid-type(36px, 48px);
    text-transform: uppercase;
  }
}

.cta-columns {
  margin-top: 32px;
  display: grid;
  grid-gap: 48px;

  @include breakpoint(md) {
    grid-gap: 36px;
    margin-top: 64px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.cta-column {
  font-size: 24px;

  @include breakpoint(md) {
    font-size: 20px;
    display: flex;
    flex-direction: column;
  }

  @include breakpoint(xl) {
    font-size: 28px;
  }

  h3 {
    font-weight: 500;
  }

  .btn {
    font-size: 24px;
    border: 1px solid black;
    display: block;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    transition: all 0.2s ease-in-out;

    @include breakpoint(lg) {
      padding: 20px 10px;
    }

    &:hover {
      background: black;
      color: white;
    }
  }

  &__actions {
    @include breakpoint(md) {
      margin-top: auto;
      padding-top: 16px;
    }
  }
}
